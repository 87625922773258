import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  useTheme,
} from '@material-ui/core/styles';
import {
  Drawer,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PrimaryNav from './PrimaryNav';
import logo from '../../assets/logo/mhub_logo_desktop.svg';
import {
  useAppNavStyles,
} from './styles';

const AppNav = props => {
  const classes = useAppNavStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const {
    showMenu, toggleMenu,
  } = props;

  const {
    pathname,
  } = window.location;

  const getModuleName = useCallback(() => {
    const arr = pathname.split('/');
    if (arr.length < 2) {
      return '';
    }
    const name = arr[1];
    return name;
  }, [pathname]);

  const primaryNavIndex = getModuleName();

  const onSelectMenu = () => {
    toggleMenu();
  };

  const renderDesktopNav = () => (
    <PrimaryNav
      selected={primaryNavIndex}
    />
  );

  const renderDrawerNav = () => (
    <Drawer
      open={showMenu}
      onClose={toggleMenu}
    >
      <div className={classes.drawerContainer}>
        <div className={classes.drawerHeader}>
          <a href="/" className={classes.logo}>
            <img alt="MHub Lead" src={logo} />
          </a>
        </div>
        <PrimaryNav
          selected={primaryNavIndex}
          onSelect={onSelectMenu}
        />
      </div>
    </Drawer>
  );

  return (
    <div className={classes.root}>
      {
        isDesktop
          ? renderDesktopNav()
          : renderDrawerNav()
      }
    </div>
  );
};

AppNav.Global = PrimaryNav;

AppNav.propTypes = {
  showMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
};

AppNav.defaultProps = {
  showMenu: false,
  toggleMenu: () => {},
};

export default AppNav;
