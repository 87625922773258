/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Route, Redirect,
} from 'react-router-dom';
import {
  AuthContext,
} from '../contexts/Auth';
import {
  decodeRedirect,
  encodeRedirect,
  getQuery,
  parseRedirect,
} from '../utils/url';

const RouteAuthenticated = ({
  path,
  component: C,
  props: cProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <AuthContext.Consumer>
        {({
          state,
        }) => {
          const {
            redirect,
          } = getQuery();
          if (state.isAuthenticated) {
            if (redirect) {
              let url = decodeRedirect(redirect);
              if (url.startsWith(window.location.origin)) {
                url = url.replace(window.location.origin, '');
              }
              return <Redirect from="/" to={url} />;
            }
            return <C {...props} {...cProps} />;
          }
          // redirect to login screen;
          return <Redirect to={`/login${parseRedirect(redirect || encodeRedirect(window.location.href))}`} />;
        }}
      </AuthContext.Consumer>
    )}
  />
);

RouteAuthenticated.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default RouteAuthenticated;
