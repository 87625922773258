import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import Amplify, {
  Auth,
} from 'aws-amplify';
import {
  createAppSyncLink,
} from 'aws-appsync';
import {
  ApolloClient,
} from 'apollo-client';
import {
  createHttpLink,
} from 'apollo-link-http';
import {
  InMemoryCache,
} from 'apollo-cache-inmemory';
import reportWebVitals from './reportWebVitals';
import config from './config';
import {
  redirectLogin,
} from './utils';
import {
  ClientProvider, ThemeProvider, SnackbarProvider, AuthProvider,
} from './contexts';
import {
  App,
} from './pages';
import './index.css';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.app.region,
    userPoolId: config.cognito.userPoolId,
    identityPoolId: config.cognito.identityPoolId,
    userPoolWebClientId: config.cognito.appClientId,
    cookieStorage: {
      domain: config.cognito.cookieDomain,
      secure: config.cognito.cookieSecure,
    },
  },
  Storage: {
    // NOTE: Public bucket is not configured as Amplify only supports one Bucket at a time.
    // Still able to call public bucket when using Storage module.
    region: config.app.region,
    bucket: config.s3.bucket,
    identityPoolId: config.cognito.identityPoolId,
  },
});

// generateLink generates appsync and apollo links
// ( appsync is using older version of react-apollo hence will not work properly )
const generateLink = endpoint => createAppSyncLink({
  url: endpoint,
  region: config.app.region,
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => Auth.currentSession().then(data => data.getIdToken().getJwtToken())
      .catch(() => {
        window.location.href = `${config.app.secureUrl}${redirectLogin()}`;
      }),
  },
  disableOffline: true,
}).concat(createHttpLink({
  uri: endpoint,
}));

// generateClient generate ApolloClient with link ( appsync and apollo links )
const generateClient = link => new ApolloClient({
  link, cache: new InMemoryCache(),
});

export const clientUser = generateClient(
  generateLink(config.appsync.userGraphqlEndpoint),
);
export const clientSubscription = generateClient(
  generateLink(config.appsync.subscriptionEndpoint),
);

ReactDOM.render(
  <ClientProvider
    clients={{
      clientUser,
      clientSubscription,
    }}
  >
    <ThemeProvider>
      <SnackbarProvider>
        <AuthProvider>
          <Router>
            <App />
          </Router>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </ClientProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
