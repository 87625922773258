import {
  makeStyles,
} from '@material-ui/core/styles';

export default makeStyles({
  loadingOverlay: {
    zIndex: 10,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(255,255,255,0.85)',
  },
});
