import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  fonts,
} from '../../contexts/Theme';

export default makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.5rem',
      '& > div': {
        flexGrow: 1,
        minHeight: '60px',
      },
      '&.expand': {
        padding: '0 0.5rem',
      },
    },
    padding: '0rem 2rem',
    border: 0,
    borderBottom: `1px solid ${theme.palette.tertiary.mediumGrey}`,
  },
  controls: {
    marginBottom: '1.5rem',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
  },
  date: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.hint,
  },
  documentTitle: {
    textAlign: 'center',
    fontFamily: fonts.titleFont,
  },
  ul: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    fontFamily: 'Montserrat, sans-serif',
    '& > li:not(:first-child):not($trailTextContainer)': {
      fontSize: '1.1rem',
      marginLeft: '1.5rem',
      '& > a': {
        textDecoration: 'none',
        color: theme.palette.font.body,
        '&:hover': {
          color: theme.palette.primary.main,
          paddingBottom: '4px',
          borderBottom: `1.3px solid ${theme.palette.primary.main}`,
        },
      },
      '&:nth-child(4)': {
        '& > a': {
          color: theme.palette.primary.main,
          paddingBottom: '4px',
          borderBottom: `1.3px solid ${theme.palette.primary.main}`,
        },
      },
    },
  },
  logo: {
    display: 'flex',
    borderRight: `1px solid ${theme.palette.tertiary.grey}`,
    paddingRight: '1rem',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& .material-icons': {
      width: '1.72rem',
      height: '1.72rem',
    },
    '& img': {
      filter: 'invert(39%) sepia(11%) saturate(707%) hue-rotate(163deg) brightness(97%) contrast(87%)',
    },
  },
  smallIcon: {
    fontSize: '0.94rem',
  },
  subFontColor: {
    color: theme.palette.font.subBody,
  },
  header: {
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.tertiary.grey}`,
    fontStyle: 'normal',
    fontWeight: '600',
  },
  menu: {
    minWidth: '200px',
    maxWidth: '200px',
    padding: 0,
    '& li': {
      height: '50px',
    },
  },
  menuIcon: {
    minWidth: '2.2rem',
    lineHeight: '0',
    '& img': {
      filter: 'invert(39%) sepia(11%) saturate(707%) hue-rotate(163deg) brightness(97%) contrast(87%)',
    },
  },
  menuItem: {
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  navigationFont: {
    fontFamily: fonts.titleFont,
    fontSize: '16px',
  },
  appsDropdownContainer: {
    '& .MuiInput-underline::before, & .MuiInput-underline::after': {
      borderBottom: 'none',
    },
  },
  appsDropdown: {
    '& > div': {
      paddingLeft: '1.7rem',
    },
    '& div > p': {
      color: theme.palette.primary.main,
      fontSize: '1.125em',
      fontWeight: 600,
      fontFamily: fonts.titleFont,
    },
    '& svg': {
      margin: '0 -1rem',
    },
  },
  navigationsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > div': {
      padding: '0 12px',
    },
  },
  headerContainer: {
    minHeight: '60px',
  },
}));
