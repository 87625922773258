export const primary = {
  main: '#49b3b1',
  dark: '#2c8483',
  light: '#32c8c5',
  contrastText: '#ffffff',
};
export const secondary = {
  main: '#0094b3',
  dark: '#016e8d',
  light: '#00bbe0',
  contrastText: '#ffffff',
};
export const tertiary = {
  grey: '#dbddde',
  dimGrey: '#99a7b0',
  lightGrey: '#f8f9f9',
  mediumGrey: '#f4f5f7',
  darkGrey: '#e7eaed',

  cyprus: '#06363c',

  green: '#5bc699',
  lightGreen: '#ecf7ee',
  darkGreen: '#50B793',

  red: '#EE0000',
  alertRed: '#FC7A71',
  alertYellow: '#FDC74C',
};
export const font = {
  header: '#00233b',
  body: '#596975',
  subBody: '#969ca4',
};
