import React from 'react';
import PropTypes from 'prop-types';

import {
  CircularProgress, Typography, Grid,
} from '@material-ui/core';

import useStyles from './styles';

const LoadingPage = ({
  isLoading, error,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <Grid container alignItems="center" justifyContent="center" className={classes.loadingPage}>
        <CircularProgress />
      </Grid>
    );
  }
  if (error) {
    return (
      <Grid container alignItems="center" justifyContent="center" className={classes.loadingPage}>
        <Typography variant="h3">
          Sorry, there was a problem loading the page.
        </Typography>
      </Grid>
    );
  }
  return null;
};

LoadingPage.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

LoadingPage.defaultProps = {
  isLoading: false,
  error: null,
};

export default LoadingPage;
