import _ from 'lodash';

import {
  getSelectorValue,
} from './elements';

export const enhancedQuery = async (
  client,
  query,
  props = {
    options: {},
    defaultData: {},
    selector: () => {},
  },
) => {
  let resp;
  try {
    resp = await client.query({
      query, ...props.options,
    });
  } catch (err) {
    // error can be handled outside. ex: snackbar
    if (props.options.onError) {
      props.options.onError(err);
    }
    // default data fallback when error occurred
    return getSelectorValue(props.defaultData, props.selector);
  }
  return getSelectorValue(
    {
      ...props.defaultData,
      ...resp.data,
    },
    props.selector,
  );
};

export const clientContextWarn = err => {
  if (
    err
    && _.has(err, 'graphQLErrors')
    && _.isArray(err.graphQLErrors)
    && err.graphQLErrors.some(
      e => e.message.includes('UnknownType')
        || e.message.includes('FieldUndefined'),
    )
  ) {
    console.warn(
      'Are you sure you are using the correct client context? Did you forgot to export your component with `withClientContext(clientName)` ?',
    );
  }
};

// Apollo pass __typename in result so need to stripe out else mutation wont work properly
export const stripTypename = value => {
  if (value === null || value === undefined) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map(v => stripTypename(v));
  }
  if (typeof value === 'object') {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = stripTypename(v);
      }
    });
    return newObj;
  }
  return value;
};
