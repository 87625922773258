export default {
  app: {
    mhubApiUrl: process.env.REACT_APP_MHUB_API_URL,
    secureUrl: process.env.REACT_APP_MHUB_SECURE_URL,
  },
  s3: {
    bucket: process.env.REACT_APP_S3_BUCKET,
  },
  cognito: {
    appClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    cookieDomain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
    cookieSecure: process.env.REACT_APP_RELEASE_STAGE === 'prod',
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  },
  appsync: {
    userGraphqlEndpoint: process.env.REACT_APP_APPSYNC_USER_ENDPOINT,
    subscriptionEndpoint: process.env.REACT_APP_APPSYNC_SUBSCRIPTION_ENDPOINT,
  },
};
