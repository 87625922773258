import {
  makeStyles,
} from '@material-ui/core/styles';

export default makeStyles({
  loading: {
    zIndex: 11,
    position: 'absolute',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.85)',
  },
  fullHeight: {
    height: '100vh',
  },
});
