import {
  makeStyles,
} from '@material-ui/core/styles';

export default makeStyles(theme => ({
  content: {
    display: 'flex',
    background: '#FFFFFF',
    '&:not(.noNav)': {
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 50px)',
        marginTop: '50px',
      },
      width: '100vw',
      height: 'calc(100vh - 60px)',
      overflow: 'hidden',
    },
  },
}));
