/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import {
  Link as RouterLink,
} from 'react-router-dom';
import {
  usePrimaryNavStyles,
} from './styles';
import {
  Tooltip,
} from '..';
import {
  HomeIcon,
  MhubAccountIcon,
} from '../../assets/icons';

const PrimaryNav = props => {
  const classes = usePrimaryNavStyles();

  const {
    selected, onSelect,
  } = props;

  function ListItemLink(properties) {
    return <ListItem button component="a" {...properties} />;
  }

  const items = [
    {
      name: '',
      title: 'Home',
      to: '/',
      icon: HomeIcon,
    },
    {
      name: 'subscription',
      title: 'MHub credit',
      to: '/subscription',
      icon: MhubAccountIcon,
    },
  ];

  return (
    <nav className={classes.root}>
      <List component="nav" className={classes.list}>
        {
          items.map(item => (
            <ListItemLink
              button
              key={item.name}
              name={item.name}
              className={classes.item}
              component={RouterLink}
              to={item.to}
              selected={selected === item.name}
              onClick={onSelect}
            >
              <ListItemIcon className={classes.icon}>
                <Tooltip title={item.title} placement="right">
                  <Icon>
                    <img alt={item.title} src={item.icon} />
                  </Icon>
                </Tooltip>
              </ListItemIcon>
              <Typography variant="h6">{ item.title }</Typography>
            </ListItemLink>
          ))
        }
      </List>
    </nav>
  );
};

PrimaryNav.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
};

PrimaryNav.defaultProps = {
  selected: '',
  onSelect: () => {},
};

export default PrimaryNav;
