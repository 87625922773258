import React from 'react';
import PropTypes from 'prop-types';

import {
  SnackbarProvider as StackSnackbarProvider,
} from 'notistack';
import {
  makeStyles,
} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));

const SnackbarProvider = props => {
  const classes = useStyles(props);

  const {
    children,
  } = props;

  return (
    <StackSnackbarProvider
      maxSnack={3}
      preventDuplicate
      iconVariant={{
        error: <ErrorIcon className={classes.icon} />,
      }}
    >
      {children}
    </StackSnackbarProvider>
  );
};

SnackbarProvider.enqueueSnackbar = (snackbarContext = {
  enqueueSnackbar: () => {}, closeSnackbar: () => {},
}, message, variant = 'default') => {
  snackbarContext.enqueueSnackbar(message, {
    variant,
    action: SnackbarProvider.defaultCloseAction(snackbarContext.closeSnackbar),
  });
};

SnackbarProvider.enqueueErrorSnackbar = (snackbarContext, message = 'An error occurred') => SnackbarProvider.enqueueSnackbar(snackbarContext, message, 'error');

SnackbarProvider.enqueueSuccessSnackbar = (snackbarContext, message) => SnackbarProvider.enqueueSnackbar(snackbarContext, message, 'success');

SnackbarProvider.enqueueInfoSnackbar = (snackbarContext, message) => SnackbarProvider.enqueueSnackbar(snackbarContext, message, 'info');

SnackbarProvider.enqueueWarningSnackbar = (snackbarContext, message) => SnackbarProvider.enqueueSnackbar(snackbarContext, message, 'warning');

SnackbarProvider.defaultCloseAction = closeSnackbar => key => (
  <IconButton color="secondary" onClick={() => closeSnackbar(key)}>
    <CloseIcon />
  </IconButton>
);

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};

SnackbarProvider.defaultProps = {
  children: null,
};

export default SnackbarProvider;
