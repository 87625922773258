import {
  createContext,
} from 'react';

export const defaultTheme = 'lead_management';

export default createContext({
  currentTheme: defaultTheme,
  actions: {
    updateTheme: () => {},
  },
});
