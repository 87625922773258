import {
  createContext,
} from 'react';

export const initialState = ({
  appLoading: false,
  snackbar: {
    visible: false,
    type: 'success',
    message: '',
  },
  navigations: [],
  isWindowActive: true,
});

export default createContext({
  state: initialState,
  actions: {
    setAppLoading: () => {},
    setIsWindowActive: () => {},
    setSnackbar: () => {},
  },
});
