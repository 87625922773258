import {
  withStyles,
} from '@material-ui/core/styles';
import {
  Tooltip,
} from '@material-ui/core';
import {
  fonts,
} from '../../contexts/Theme';

export default withStyles(theme => ({
  tooltip: {
    ...fonts.caption,
    color: theme.palette.common.white,
    backgroundColor: '#06363C',
  },
}))(Tooltip);
