import {
  makeStyles,
} from '@material-ui/core';
import {
  fonts,
} from '../../contexts';

export default makeStyles(theme => ({
  header: {
    fontWeight: fonts.bold,
    color: theme.palette.font.header,
    textTransform: 'uppercase',
    padding: '1.3rem 1.15rem 0.86rem 1.15rem',
    fontSize: '14px',
  },
  list: {
    '& li:hover': {
      backgroundColor: `${theme.palette.tertiary.mediumGrey}`,
    },
    '& li + $selected': {
      backgroundColor: `${theme.palette.tertiary.mediumGrey}`,
    },
  },
  selected: {},
}));
