import {
  createContext,
} from 'react';
import Immutable from 'seamless-immutable';

export const initialState = Immutable({
  profileUrl: '',
  error: null,
  isAuthenticated: false,
  isError: false,
  isLoggedOut: false,
  isLoading: false,
  user: {
    id: '',
    name: '',
    email: '',
    phone_number: '',
    groups: [],
    role: '',
  },
  users: {},
  userOptions: [],
  activeUserOptions: [],
  subscriptionList: [],
  listCompanyBranches: [],
  company: {},
});

export default createContext({
  state: initialState,
  actions: {
    authenticate: () => {},
    signOut: () => {},
  },
});
