// eslint-disable-next-line import/no-cycle
import {
  CONSTRUCTOR,
} from '..';

// subscription fields fragment.
export const Subscription = CONSTRUCTOR.FRAGMENT({
  name: 'Subscription',
  fields: `{
    id
    amount
    company_branch_id
    company_id
    created_at
    created_by
    currency_code
    updated_at
    updated_by
  }`,
});

// ListSubscriptionResponse fields fragment.
export const listSubscriptionsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListSubscriptionsResponse',
  fields: `{
    items {...SubscriptionFields}
    count
  }`,
  fragments: [Subscription],
});

// ListActivitiesResponse fields fragment.
export const listActivitiesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListActivitiesResponse',
  fields: `{
    items
    count
  }`,
});

// GetTotalCreditsResponse fields fragment
export const getTotalCreditsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'GetTotalCreditsResponse',
  fields: `{
    amount
  }`,
});
