import Bugsnag from '@bugsnag/js';
import qs from 'query-string';

function objHasKeys(obj) {
  if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
    const keys = Object.keys(obj);
    return keys.length > 0;
  }
  return false;
}

export const getQuery = (location = window.location) => qs.parse(location.search);

export const getHash = (location = window.location) => qs.parse(location.hash);

export function parseParams(url, query = {}, hash = {}) {
  let parsedURL = url;
  if (query && objHasKeys(query)) {
    parsedURL += `?${qs.stringify(query)}`;
  }
  if (hash && objHasKeys(hash)) {
    parsedURL += `#${qs.stringify(hash)}`;
  }
  return parsedURL;
}

export function encodeRedirect(url = '/') {
  let nextBase64 = '';
  if (url) {
    try {
      nextBase64 = btoa(url);
    } catch (e) {
      Bugsnag.notify(e);
    }
  }
  return nextBase64;
}

export function decodeRedirect(nextBase64) {
  let url = '';
  if (nextBase64) {
    try {
      url = atob(nextBase64);
    } catch (e) {
      Bugsnag.notify(e);
    }
  }
  return url;
}

export function parseRedirect(nextBase64) {
  return `?redirect=${nextBase64}`;
}

export function redirectLogin() {
  return `/login${parseRedirect(
    getQuery().redirect || encodeRedirect(window.location.href),
  )}`;
}

export function urlParamsToJSON(type) {
  let result = {};
  const {
    search,
  } = window.location;

  if (search) {
    const params = qs.parse(window.location.search);
    Object.keys(params).forEach(param => {
      if (Array.isArray(params[param])) {
        result = {
          ...result, [param]: params[param],
        };
      } else if (type === 'decode') {
        try {
          // Deep decode
          const decoded = decodeURIComponent(params[param]);
          if (decoded) {
            try {
              result = {
                ...result, [param]: JSON.parse(decoded),
              };
            } catch {
              result = {
                ...result, [param]: decoded,
              };
            }
          }
        } catch {
          delete result[param];
        }
      } else {
        result = {
          ...result,
          [param]: params[param],
        };
      }
    });
  }
  return result;
}
