import {
  createMuiTheme,
} from '@material-ui/core';
import * as font from './font';
import * as color from './color';

const defaultTheme = createMuiTheme();

export const leadTheme = createMuiTheme({
  shadows: Array(25).fill('none'),
  palette: {
    primary: color.primary,
    secondary: color.secondary,
    tertiary: color.tertiary,
    font: color.font,
    text: {
      primary: color.font.header,
    },
    error: {
      main: color.tertiary.red,
      dark: defaultTheme.palette.error.dark,
      light: color.tertiary.alertRed,
    },
  },
  typography: {
    fontFamily: font.bodyFont,
    color: color.font.body,
  },
  overrides: {
    MuiTypography: {
      h1: font.h1,
      h2: font.h2,
      h3: font.h3,
      h4: font.h4,
      h5: font.h5,
      h6: font.h6,
      subtitle1: font.subtitle1,
      subtitle2: font.subtitle2,
      body1: font.body1,
      body2: font.body2,
      caption: font.caption,
    },

    MuiAutocomplete: {
      endAdornment: {
        top: 'unset',
      },
    },

    MuiButtonGroup: {
      groupedContained: {
        ...font.buttonFont,
      },
    },

    MuiButton: {
      root: {
        ...font.buttonFont,
        minWidth: '70px',
        minHeight: '35px',
        padding: '0 1rem',
        '&:disabled': {
          color: color.tertiary.grey,
          '& img': {
            filter: 'invert(50%)',
            opacity: '0.5',
          },
        },
      },
      label: {
        textTransform: 'none',
      },
      contained: {
        backgroundColor: color.secondary.main,
        color: defaultTheme.palette.common.white,
        '&:hover': {
          backgroundColor: color.secondary.light,
        },
        '&:disabled': {
          color: color.tertiary.grey,
          backgroundColor: color.tertiary.mediumGrey,
          '& img': {
            filter: 'invert(50%)',
            opacity: '0.5',
          },
        },
        '&:not(:disabled) img': {
          filter: 'invert(1)',
        },
      },
      text: {
        padding: '0.36rem 1.43rem',
        '&:hover': {
          backgroundColor: 'unset',
          color: color.font.body,
        },
        '&:active': {
          color: color.font.subBody,
        },
        '&:disabled': {
          color: color.tertiary.grey,
        },
      },
      outlined: {
        color: color.font.body,
        borderColor: color.tertiary.grey,
        padding: '0 1rem',
        '&:hover': {
          borderColor: color.tertiary.mediumGrey,
          backgroundColor: color.tertiary.mediumGrey,
        },
        '&:disabled': {
          border: `1px solid ${color.tertiary.lightGrey}`,
          backgroundColor: color.tertiary.mediumGrey,
          '& img': {
            opacity: '0.2',
          },
        },
        '&:not(:disabled) img': {
          filter: 'invert(42%) sepia(23%) saturate(316%) hue-rotate(163deg) brightness(89%) contrast(92%)',
        },
      },
      sizeSmall: {
        ...font.smallButtonFont,
        minWidth: 'unset',
        minHeight: '30px',
        padding: '0 1rem',
        '& .MuiButton-label .MuiButton-startIcon img': {
          width: '20px',
          height: '18px',
        },
      },
      startIcon: {
        marginRight: '4px',
      },
    },

    MuiCheckbox: {
      root: {
        '& > span:first-child svg': {
          width: '18px',
          height: '18px',
        },
      },
      colorSecondary: {
        color: color.tertiary.grey,
        '&$checked': {
          color: color.tertiary.green,
        },
        '&$disabled': {
          color: color.tertiary.mediumGrey,
        },
      },
    },

    MuiRadio: {
      root: {
        '& .MuiSvgIcon-root': {
          height: '18px',
          width: '18px',
        },
      },
      colorSecondary: {
        color: color.tertiary.grey,
        '&$disabled': {
          color: color.tertiary.mediumGrey,
        },
        '&$checked': {
          color: color.tertiary.green,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    MuiDialogTitle: {
      root: {
        ...font.modalTitleFont,
      },
    },

    MuiTableRow: {
      hover: {
        '&$hover:hover': {
          backgroundColor: color.tertiary.mediumGrey,
        },
      },
    },

    MuiTableCell: {
      root: {
        '&:not(:last-child)': {
          paddingRight: '1.5rem',
        },
        '&:not(:first-child)': {
          paddingLeft: '0.5rem',
        },
      },
      head: {
        ...font.subtitle1,
        padding: '0.5rem 0',
        borderColor: color.tertiary.mediumGrey,
        whiteSpace: 'noWrap',
        maxWidth: '18rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        tableLayout: 'fixed',
      },
      body: {
        height: '40px',
        padding: '0',
        fontSize: '1rem',
        borderColor: color.tertiary.lightGrey,
        '& p': {
          margin: 0,
        },
      },
    },

    MuiInputLabel: {
      formControl: {
        position: 'unset',
        transform: 'unset',
        marginBottom: '0.5rem',
        ...font.subtitle2,
      },
      root: {
        ...font.subtitle2,
        marginBottom: '0.5rem',
        '& .MuiInputLabel-asterisk': {
          color: color.tertiary.red,
        },
      },
    },

    MuiFormLabel: {
      root: {
        ...font.subtitle2,
        marginBottom: '0.5rem',
        '& .MuiFormLabel-asterisk': {
          color: color.tertiary.red,
        },
      },
    },

    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: color.tertiary.grey,
        },
      },
    },

    MuiFormHelperText: {
      contained: {
        marginTop: '3px',
        marginLeft: 0,
        marginRight: 0,
      },
    },

    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: color.tertiary.lightGrey,
        },
        '&:disabled': {
          color: color.tertiary.grey,
        },
      },
      colorSecondary: {
        color: defaultTheme.palette.common.white,
      },
    },

    MuiIcon: {
      root: {
        '& img': {
          width: '100%',
          height: '100%',
        },
      },
    },

    MuiList: {
      root: {
        '&:focus': {
          outline: 0,
        },
      },
    },

    MuiListItem: {
      root: {
        '&$disabled': {
          opacity: '0.3',
        },
      },
    },

    MuiMenuItem: {
      root: {
        minWidth: '18ch',
        minHeight: 'auto',
      },
    },

    MuiLink: {
      root: {
        color: color.primary.main,
        '&:hover': {
          cursor: 'pointer',
          color: color.primary.light,
        },
        '&:disabled': {
          color: color.tertiary.grey,
        },
      },
    },

    MuiMenu: {
      paper: {
        border: `1px solid ${color.tertiary.grey}`,
      },
      list: {
        'min-width': '80px',
      },
    },

    MuiInputBase: {
      root: {
        borderRadius: '4px',
        '&$disabled': {
          color: color.font.subBody,
          backgroundColor: color.tertiary.mediumGrey,
        },
        '&$disabled > svg': {
          color: color.tertiary.grey,
        },
        'label[data-shrink=false] + &$formControl input::-webkit-input-placeholder': {
          color: color.font.subBody,
          opacity: '1 !important',
        },
      },
      input: {
        '&:-webkit-autofill': {
          boxShadow: '0 0 0 30px white inset',
        },
        '&::placeholder': {
          color: color.font.subBody,
          opacity: '1 !important',
        },
      },
    },

    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: `1px solid ${color.primary.main}`,
        },
        '&$focused:not($disabled):not($error) $notchedOutline': {
          border: `1px solid ${color.primary.main}`,
        },
        '&$disabled $notchedOutline': {
          borderColor: color.tertiary.mediumGrey,
        },
      },
      input: {
        border: '1px solid transparent',
        boxSizing: 'border-box',
        height: '40px',
        padding: '0.7rem 1rem',
      },
      notchedOutline: {
        borderColor: color.tertiary.grey,
      },
    },

    MuiPickersBasePicker: {
      pickerView: {
        width: '355px',
        maxWidth: '355px',
        '& .MuiPickersMonthSelection-container': {
          width: 'unset',
        },
        [defaultTheme.breakpoints.down('xs')]: {
          width: 'unset',
          minWidth: 'unset',
        },
      },
    },

    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '180px',
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        padding: '0 30px',
        '& > button:first-child': {
          paddingLeft: 0,
        },
        '& > button:last-child': {
          paddingRight: 0,
        },
        '& p': {
          fontSize: '12px',
        },
      },
      daysHeader: {
        '& $dayLabel': {
          color: color.font.subBody,
          fontWeight: font.bold,
        },
      },
      transitionContainer: {
        '& > p': {
          color: color.font.header,
          fontWeight: font.semiBold,
        },
      },
    },

    MuiPaper: {
      root: {
        border: `1px solid ${color.tertiary.grey}`,
      },
    },

    MuiSelect: {
      outlined: {
        '&.MuiSelect-outlined': {
          paddingRight: '3rem',
        },
      },
      icon: {
        width: '2.5rem',
        color: color.font.body,
      },
      select: {
        '&:focus': {
          backgroundColor: 'unset',
        },
        '&$disabled': {
          border: `1px solid ${color.tertiary.mediumGrey}`,
          '& p': {
            color: color.font.subBody,
          },
        },
      },
      selectMenu: {
        boxSizing: 'border-box',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
      },
    },

    MuiSwitch: {
      root: {
        width: 30,
        height: 15,
        padding: 0,
        margin: defaultTheme.spacing(1),
      },
      colorSecondary: {
        '&$thumb': {
          backgroundColor: color.tertiary.grey,
        },
        '&$checked': {
          color: defaultTheme.palette.common.white,
          '& + $track': {
            backgroundColor: color.tertiary.green,
          },
          '&$disabled': {
            color: defaultTheme.palette.common.white,
            '& + $track': {
              backgroundColor: color.tertiary.mediumGrey,
            },
          },
        },
        '&$disabled': {
          color: defaultTheme.palette.common.white,
          '& + $track': {
            backgroundColor: color.tertiary.mediumGrey,
          },
        },
      },
      switchBase: {
        padding: 0,
        color: defaultTheme.palette.common.white,
        border: `1px solid ${color.tertiary.mediumGrey}`,
        '&$checked': {
          transform: 'translateX(15px)',
          border: `1px solid ${color.primary.main}`,
          '&$disabled': {
            border: `1px solid ${color.tertiary.mediumGrey}`,
          },
          '& + $track': {
            opacity: 1,
          },
        },
        '&$disabled + $track': {
          opacity: 1,
        },
      },
      track: {
        borderRadius: 26 / 2,
        opacity: 1,
        transition: defaultTheme.transitions.create(['background-color', 'border', 'transform']),
        backgroundColor: color.tertiary.grey,
      },
      thumb: {
        width: 13,
        height: 13,
      },
    },

    MuiChip: {
      root: {
        color: color.font.body,
      },
    },

    MuiTooltip: {
      tooltipPlacementBottom: {
        margin: '0.5rem 0 0 0',
        [defaultTheme.breakpoints.down('md')]: {
          margin: '0.5rem 0 0 0',
        },
      },
    },
  },
});

const defaultMuiTheme = createMuiTheme();

export default theme => {
  switch (theme) {
    case 'lead_management':
      return leadTheme;
    // ... other themes
    default:
      return defaultMuiTheme;
  }
};
