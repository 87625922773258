import React, {
  useCallback, useState,
} from 'react';
import {
  CssBaseline,
} from '@material-ui/core';
import {
  withRouter,
} from 'react-router';
import {
  AuthProvider,
} from '../../contexts';
import Routes from '../../Routes';
import useStyles from './styles';
import {
  AppBar, AppNav,
} from '../../components';

const App = () => {
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = useCallback(() => {
    setShowMenu(prev => !prev);
  }, []);

  return (
    <>
      <AuthProvider>
        <CssBaseline />
        <AppBar toggleMenu={toggleMenu} />
        <div className={classes.content}>
          <AppNav showMenu={showMenu} toggleMenu={toggleMenu} />
          <Routes />
        </div>
      </AuthProvider>
    </>
  );
};

export default withRouter(App);
