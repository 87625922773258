/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer as MuiDrawer,
  Grid,
  Typography,
  IconButton,
  Button,
  MenuList,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LoadingButton from '../loader/LoadingButton';
import DropdownMenu from '../DropdownMenu';
import {
  ArrowIcon,
} from '../../assets/icons';
import {
  useStyles, useContainerStyles, useContentStyles,
} from './styles';

const Drawer = props => {
  const {
    children,
    header,
    type,
    cancelLabel,
    submitLabel,
    mobileNavTitle,

    navigations,
    secondaryButtons,

    open,
    showCancel,
    showSideNav,
    hideClose,
    hideFooter,
    isLoading,
    isCancelDisabled,
    isSubmitDisabled,
    isWideDrawer,

    onCancel,
    onClickBack,
    onClose,
    onSubmit,

    ...drawerProps
  } = props;

  const classes = useStyles(props);
  const containerClasses = useContainerStyles(
    props,
    isWideDrawer || showSideNav,
  );
  const contentClasses = useContentStyles(props, showSideNav);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const renderTitleSection = () => {
    if (type === 'back') {
      return (
        <Grid container className={classes.headerBar}>
          <IconButton
            size="small"
            onClick={onClickBack}
            disabled={isLoading}
            className={classes.backIcon}
          >
            <img alt="menu" src={ArrowIcon} />
          </IconButton>
          <Typography variant="h6">{header}</Typography>
        </Grid>
      );
    }
    if (type === 'close' && !hideClose) {
      return (
        <Grid
          container
          className={`${classes.headerBar} leftPadding`}
          justifyContent="space-between"
        >
          <Typography variant="h6">{header}</Typography>
          <IconButton onClick={onClose} disabled={isLoading}>
            <CloseIcon />
          </IconButton>
        </Grid>
      );
    }
    return null;
  };

  const renderButton = (property, load) => {
    const {
      label,
      variant,
      onClick,
      className,
      isDisabled = false,
    } = property;
    if (load) {
      return <LoadingButton variant={variant} />;
    }
    return (
      <Button
        variant={variant}
        onClick={onClick}
        className={className}
        disabled={isDisabled}
      >
        {label}
      </Button>
    );
  };

  const renderButtons = () => (
    <>
      {showCancel
        && renderButton(
          {
            label: cancelLabel,
            variant: 'outlined',
            onClick: onCancel != null ? onCancel : onClose,
            className: classes.cancelButton,
            isDisabled: isCancelDisabled,
          },
          isLoading,
        )}
    </>
  );

  const renderDesktopSideNavigations = () => (
    <MenuList>
      {navigations.map(item => (
        <MenuItem key={item.name} onClick={item.onClick}>
          {item.name}
        </MenuItem>
      ))}
    </MenuList>
  );

  const renderMobileSideNavigations = () => (
    <DropdownMenu
      backdrop
      className={classes.mobileSideNavigation}
      data={navigations.map(nav => ({
        children: nav.name,
        onClick: nav.onClick,
      }))}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      {({
        open: openDropdown,
      }) => (
        <div className={classes.categoryButton}>
          <Button variant="outlined" onClick={openDropdown}>
            {mobileNavTitle}
          </Button>
        </div>
      )}
    </DropdownMenu>
  );

  const renderDrawerContent = () => (
    <div className={classes.drawer}>
      {renderTitleSection()}
      <Grid container direction="row" className={containerClasses.container}>
        {!isMobile && showSideNav && (
          <Grid item className={classes.sideNavigations}>
            {!isMobile && renderDesktopSideNavigations()}
          </Grid>
        )}
        {isMobile && showSideNav && renderMobileSideNavigations()}
        <Grid
          item
          className={contentClasses.content}
          style={{
            padding: 0,
          }}
          id="drawer-content"
        >
          <div className="scrollableContent">{children}</div>
        </Grid>
      </Grid>
      {!hideFooter && (
      <Grid
        container
        className={classes.footer}
        alignItems="center"
        justifyContent={secondaryButtons.length > 0 ? 'space-between' : 'flex-end'}
      >
        {secondaryButtons.length > 0 && (
        <Grid item className={classes.secondaryButtons}>
          {secondaryButtons.map(opt => {
            if (opt.label && opt.onClick) {
              return (
                <Button
                  size="small"
                  key={opt.label}
                  onClick={opt.onClick}
                >
                  {opt.label}
                </Button>
              );
            }
            return null;
          })}
        </Grid>
        )}
        <Grid item className={classes.primaryButtons}>
          {renderButtons()}
        </Grid>
      </Grid>
      )}
    </div>
  );

  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        id: 'drawer',
      }}
      {...drawerProps}
    >
      {renderDrawerContent()}
    </MuiDrawer>
  );
};

Drawer.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  mobileNavTitle: PropTypes.string,
  type: PropTypes.oneOf(['close', 'back']),

  navigations: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  secondaryButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),

  showSideNav: PropTypes.bool,
  hideClose: PropTypes.bool,
  hideFooter: PropTypes.bool,
  open: PropTypes.bool,
  showCancel: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCancelDisabled: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  isWideDrawer: PropTypes.bool,

  onCancel: PropTypes.func,
  onClickBack: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

Drawer.defaultProps = {
  children: null,
  header: '',
  cancelLabel: 'Cancel',
  submitLabel: 'Submit',
  mobileNavTitle: '',
  type: 'close',

  navigations: [],
  secondaryButtons: [],

  showSideNav: false,
  hideClose: false,
  hideFooter: false,
  open: false,
  showCancel: false,
  isLoading: false,
  isCancelDisabled: false,
  isSubmitDisabled: false,
  isWideDrawer: false,

  onCancel: null,
  onClickBack: () => {},
  onClose: () => {},
  onSubmit: () => {},
};

export default Drawer;
