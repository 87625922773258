import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  fonts,
} from '../../contexts/Theme';

export default makeStyles(() => ({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'backgroundColor 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  item: {
    '& > li': {
      ...fonts.body2,
    },
  },
}));
