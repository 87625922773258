import React from 'react';
import PropTypes from 'prop-types';

import {
  CircularProgress, Grid,
} from '@material-ui/core';

import useStyles from './styles';

const LoadingContent = ({
  isLoading, fullHeight,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={`${classes.loading} ${fullHeight ? classes.fullHeight : ''}`}
      >
        <CircularProgress />
      </Grid>
    );
  }
  return null;
};

LoadingContent.propTypes = {
  isLoading: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

LoadingContent.defaultProps = {
  isLoading: false,
  fullHeight: false,
};

export default LoadingContent;
