import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';
import {
  Auth,
} from 'aws-amplify';
import Bugsnag from '@bugsnag/js';
import {
  withSnackbar,
} from 'notistack';
import AuthContext, {
  initialState,
} from './context';
import SnackbarProvider from '../Snackbar';
import {
  redirectLogin,
} from '../../utils';
import config from '../../config';
import {
  SUBSCRIPTION, USER,
} from '../../graphql';
import {
  enhancedQuery,
} from '../../utils/graphql';
import {
  clientUser, clientSubscription,
} from '../../index';

class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: initialState,
    };
  }

  componentDidMount() {
    this.authenticate();
  }

  componentWillUnmount() {
    const {
      closeSnackbar,
    } = this.props;
    closeSnackbar();
  }

  setStateAsync(newState = {}) {
    return new Promise(resolve => {
      this.setState(
        prevState => ({
          data: Immutable.merge(prevState.data, newState),
        }),
        () => resolve(newState),
      );
    });
  }

  async getCurrentSession() {
    let resp;
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      resp = authUser.getSignInUserSession();
    } catch (error) {
      window.location.href = `${config.app.secureUrl}${redirectLogin()}`;

      await this.setStateAsync({
        hasAccess: false,
        isAuthenticated: false,
        isLoading: false,
      });
      return null;
    }
    return resp;
  }

  static async getUserList(users, listCompanyBranches, convertToObject) {
    // Set users as object with userId as key
    // For accessing user info by user id
    if (convertToObject) {
      const mapped = users.map(u => {
        const companyBranchId = u.company_branch_id;
        const companyBranchName = (listCompanyBranches[companyBranchId]
          && listCompanyBranches[companyBranchId].name)
          || '';
        return {
          [u.id]: {
            ...u,
            company_branch_name: companyBranchName,
          },
        };
      });
      const objects = Object.assign({}, ...mapped);
      return objects;
    }

    const options = users.map(u => {
      const companyBranchId = u.company_branch_id;
      const companyBranchName = (listCompanyBranches[companyBranchId]
        && listCompanyBranches[companyBranchId].name)
        || '';
      return {
        label: u.name,
        value: u.id,
        company_branch_name: companyBranchName,
        ...u,
      };
    });
    return options;
  }

  static async getUserData(resp) {
    let groups = [];
    // FIXME: aws amplify currently don have groups attribute in user info,
    // currently need to get from idToken payload get user's groups from cognito
    const attributes = resp.getIdToken().decodePayload();
    const {
      name, email, phone_number: phoneNumber, sub,
    } = attributes;
    ({
      'cognito:groups': groups,
    } = attributes);
    const user = {
      id: sub,
      name,
      email,
      phone_number: phoneNumber,
      groups,
      company_id: attributes['custom:company_id'],
      company_branch_id: attributes['custom:company_branch_id'],
    };
    return user;
  }

  async getUsers() {
    const {
      users,
    } = await this.fetchUsers();
    if (!users) {
      return [];
    }
    return users;
  }

  async getSubscriptionList() {
    const {
      items,
    } = await this.fetchSubscriptionList();
    if (!items) {
      return [];
    }
    return items;
  }

  async fetchUsers() {
    const {
      closeSnackbar, enqueueSnackbar,
    } = this.props;
    return enhancedQuery(clientUser, USER.LIST(), {
      options: {
        onError: () => SnackbarProvider.enqueueErrorSnackbar({
          closeSnackbar,
          enqueueSnackbar,
        }),
      },
      selector: d => d.listUsers,
    });
  }

  async fetchSubscriptionList() {
    const {
      closeSnackbar, enqueueSnackbar,
    } = this.props;
    return enhancedQuery(clientSubscription, SUBSCRIPTION.LIST_SUBSCRIPTIONS(), {
      options: {
        onError: () => SnackbarProvider.enqueueErrorSnackbar({
          closeSnackbar,
          enqueueSnackbar,
        }),
      },
      selector: d => d.listSubscriptions,
    });
  }

  async fetchListCampanyBranch() {
    const {
      closeSnackbar, enqueueSnackbar,
    } = this.props;
    let list = await enhancedQuery(clientUser, USER.LIST_COMPANY_BRANCH(), {
      options: {
        onError: () => SnackbarProvider.enqueueErrorSnackbar({
          closeSnackbar,
          enqueueSnackbar,
        }),
      },
      selector: d => d.listCompanyBranches,
    });
    list = list.company_branches || [];
    const mapped = list.map(bu => ({
      [bu.id]: bu,
    }));
    return Object.assign({}, ...mapped);
  }

  async authenticate() {
    await this.setStateAsync({
      isLoading: true,
      hasAccess: false,
      isAuthenticated: false,
    });

    const resp = await this.getCurrentSession();

    let isAuthenticated = false;
    let user = {
      groups: [],
    };
    const res = await fetch(`${config.app.mhubApiUrl}/authenticated/`, {
      credentials: 'include',
      mode: 'cors',
    });

    const data = await res.json();
    const {
      company,
    } = data.affiliate;
    const {
      apps,
    } = company;
    const {
      webURL,
    } = apps.find(app => app.appID === 'showroom');
    const profileUrl = webURL;
    if (resp) {
      user = await this.constructor.getUserData(resp);
      Bugsnag.addMetadata('user', {
        user_id: user.id,
        name: user.name,
        email: user.email,
        company_id: user.company_id,
      });
      isAuthenticated = true;
    }

    // Retrieve user list
    const listUsers = await this.getUsers();

    // Retrieve subscription list
    const subscriptionList = await this.getSubscriptionList();

    const listCompanyBranches = await this.fetchListCampanyBranch();

    const users = await this.constructor.getUserList(listUsers, listCompanyBranches, true);

    const userIds = Object.values(users).map(u => u.id);
    if (userIds && userIds.length) {
      const userExist = userIds.filter(id => id === user.id).length > 0;
      if (!userExist) {
        users[user.id] = user;
        Bugsnag.notify('User not found');
      }
    }

    await this.setStateAsync({
      isAuthenticated,
      user,
      company,
      users,
      listCompanyBranches,
      profileUrl,
      subscriptionList,
      isLoading: false,
    });
  }

  // Sign out of cognito session.
  // Redirect to login app's signout page to let login app handle signout session flow.
  async signOut() {
    window.location.href = `${config.app.secureUrl}/logout`;
    await this.setStateAsync({
      authenticated: false,
      hasAccess: false,
      hasHigherAccess: false,
      isLoading: false,
      isLoggedOut: true,
    });
  }

  render() {
    const {
      children,
    } = this.props;
    const {
      data,
    } = this.state;
    const value = {
      state: data,
      actions: {
        authenticate: this.authenticate,
        signOut: this.signOut,
      },
    };
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }
}

// LMS-970: AWS Cognito ID and access tokens expire after 1 hour
// Here with make a request to auth every 30 minutes to keep the connection alive
// Refer: https://docs.aws.amazon.com/cognito/latest/developerguide/limits.html#limits-hard
setInterval(async () => {
  try {
    await Auth.currentSession();
  } catch {
    window.location.href = `${config.app.secureUrl}${redirectLogin()}`;
  }
}, 1800000);

AuthProvider.displayName = 'AuthProvider';
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  // props from notistack package context
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(AuthProvider);
