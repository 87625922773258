import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
} from 'react';
import {
  Switch, useHistory,
} from 'react-router-dom';

import {
  RouteAuthenticated,
  LoadingPage,
} from './components';
import {
  PageContext,
} from './contexts';

const Home = lazy(() => import('./pages/Home'));
const Subscription = lazy(() => import('./pages/Subscription'));

const Routes = () => {
  const pageContext = useContext(PageContext);
  const {
    appLoading,
  } = pageContext.state;
  const {
    setAppLoading,
  } = pageContext.actions;

  const history = useHistory();
  // If router change and got webLoad, switch it to false
  useEffect(() => {
    if (appLoading) {
      history.listen(() => {
        setAppLoading(false);
      });
    }
  }, [history, appLoading, setAppLoading]);

  return (
    <Suspense fallback={<LoadingPage isLoading />}>
      <Switch>
        <RouteAuthenticated path="/subscription" component={Subscription} />
        <RouteAuthenticated path="" exact component={Home} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
