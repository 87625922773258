/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef,
} from 'react';

import ClientConsumer from './Consumer';

// example usage :
// withClientContext('client')(Component);

export default name => WrappedComponent => forwardRef((props, ref) => (
  <ClientConsumer name={name}>
    <WrappedComponent {...props} ref={ref} />
  </ClientConsumer>
));
