import React, {
  useReducer, useCallback,
} from 'react';
import {
  ThemeProvider as Provider,
} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ThemeContext, {
  defaultTheme,
} from './context';

import getTheme from './theme';

const themeReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_THEME':
      return action.payload;
    default:
      return state;
  }
};

const ThemeProvider = ({
  children,
}) => {
  const [currentTheme, dispatch] = useReducer(themeReducer, defaultTheme);
  const updateTheme = useCallback(newTheme => {
    dispatch({
      type: 'UPDATE_THEME', payload: newTheme,
    });
  }, []);
  const context = {
    currentTheme,
    actions: {
      updateTheme,
    },
  };
  const theme = getTheme(currentTheme);
  return (
    <ThemeContext.Provider value={context}>
      <Provider theme={theme}>
        {children}
      </Provider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.Consumer = ThemeContext.Consumer;

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

ThemeProvider.defaultProps = {
  children: null,
};

export default ThemeProvider;
