// eslint-disable-next-line import/no-cycle
import {
  CONSTRUCTOR,
} from '..';

// User fields fragment.
export const user = CONSTRUCTOR.FRAGMENT({
  name: 'User',
  fields: `{
    id
    company_id
    company_branch_id
    name
    preferred_name
    email
    phone_number
    email_verified
    phone_number_verified
    created_at
    last_modified
    user_status
    sms_mfa_status
    groups
  }`,
});

// User group fields fragment.
export const userGroup = CONSTRUCTOR.FRAGMENT({
  name: 'UserGroup',
  fields: `{
    id
    company_id
    name
    description
    user_ids
    created_at
    created_by
    updated_at
    updated_by
  }`,
});

// Company branch fields fragment.
export const companyBranch = CONSTRUCTOR.FRAGMENT({
  name: 'CompanyBranch',
  fields: `{
    id
    name
  }`,
});

// ListUserGroups fields fragment.
export const listUserGroupsResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListUserGroupsResponse',
  fields: `{
    user_groups {...UserGroupFields}
    count
  }`,
  fragments: [userGroup],
});

// ListUsers fields fragment.
export const listUserResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListUserResponse',
  fields: `{
    users {...UserFields}
    count
  }`,
  fragments: [user],
});

// ListCompanyBranches fields fragment.
export const listCompanyBranchesResponse = CONSTRUCTOR.FRAGMENT({
  name: 'ListCompanyBranchesResponse',
  fields: `{
    company_branches {...CompanyBranchFields}
    count
  }`,
  fragments: [companyBranch],
});
