/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Menu as MuiMenu,
} from '@material-ui/core';
import Menu from '../Menu';
import useStyles from './styles';

const DropdownMenu = props => {
  const classes = useStyles();
  const {
    anchorOrigin, transformOrigin,
    children, headerNode,
    backdrop,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOnOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {typeof children === 'function' ? children({
        isOpen: Boolean(anchorEl), open: handleOnOpen, close: handleOnClose,
      }) : children}
      <MuiMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleOnClose}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        MenuListProps={{
          disablePadding: true,
        }}
        disableAutoFocusItem
        className={backdrop ? classes.backdrop : ''}
      >
        { headerNode }
        <Menu className={classes.item} onClick={handleOnClose} {...rest} />
      </MuiMenu>
    </>
  );
};

DropdownMenu.propTypes = {
  backdrop: PropTypes.bool,
  anchorOrigin: PropTypes.instanceOf(Object),
  transformOrigin: PropTypes.instanceOf(Object),
  headerNode: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
};

DropdownMenu.defaultProps = {
  backdrop: false,
  anchorOrigin: {
    vertical: 'bottom', horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top', horizontal: 'right',
  },
  headerNode: null,
  children: null,
};

export default DropdownMenu;
