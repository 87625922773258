import React, {
  useState, useContext,
} from 'react';
import {
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar as MuiAppBar,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  BurgerMenuIcon,
  ProfileIcon,
  SignoutIcon,
  HomeIcon,
} from '../../assets/icons';
import logo from '../../assets/logo/mhub_logo_desktop.svg';
import {
  DropdownMenu, Tooltip,
} from '..';
import useStyles from './styles';
import {
  AuthContext,
} from '../../contexts';

const AppBar = props => {
  const theme = useTheme();
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const {
    toggleMenu,
  } = props;
  const {
    signOut,
  } = authContext.actions;
  const [activeStates, setActiveStates] = useState({
    signOut: false,
  });
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    profileUrl, user,
  } = authContext.state;

  const handleSignOut = () => {
    signOut();
  };

  const handleOpenState = name => () => {
    setActiveStates(s => ({
      ...s,
      [name]: true,
    }));
  };

  const handleCloseState = name => () => {
    setActiveStates(s => ({
      ...s,
      [name]: false,
    }));
  };

  const handleOpenURL = (url, newTab) => {
    const URL = url;
    if (newTab) {
      window.open(URL, '_blank');
      return;
    }
    window.location.href = URL;
  };

  const renderMenuHeader = text => (
    <Typography variant="subtitle2" className={classes.header}>
      {text}
    </Typography>
  );

  const renderMenuItem = (text, icon) => (
    <>
      {icon && (
        <ListItemIcon className={classes.menuIcon}>
          <Icon>
            <img alt="" src={icon} />
          </Icon>
        </ListItemIcon>
      )}
      <Typography variant="subtitle2" className={classes.menuItem}> { text } </Typography>
    </>
  );

  const handleOnChangePage = e => {
    const {
      value,
    } = e.target;
    switch (value) {
      case 'Home':
        window.location.href = '/';
        break;
      case 'MHub credit':
        window.location.href = '/subscription';
        break;
      default:
    }
  };

  const renderAppsDrowdown = () => (
    <Grid container justifyContent="center">
      <FormControl className={classes.appsDropdownContainer}>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          value="home"
          onChange={handleOnChangePage}
          className={classes.appsDropdown}
        >
          <MenuItem value="home">
            <Typography>Home</Typography>
          </MenuItem>
          <MenuItem value="subscription">
            <Typography>MHub credit</Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );

  const currentLocation = useLocation().pathname.substring(1);
  const renderTitle = () => {
    let title = '';
    const secondaryLocationPath = currentLocation.indexOf('/');
    if (secondaryLocationPath >= 0) {
      title = currentLocation.substr(0, secondaryLocationPath);
    } else {
      title = currentLocation;
    }
    switch (title) {
      case '':
        title = 'Home';
        break;
      case 'subscription':
        title = 'Account';
        break;
      default:
    }
    return (
      <Typography variant="h6" className={classes.documentTitle}>
        {title.charAt(0).toUpperCase() + title.slice(1)}
      </Typography>
    );
  };

  const renderHeading = () => {
    // to only show apps drowdown when on the homepage
    if (currentLocation === '') {
      return renderAppsDrowdown();
    }
    return renderTitle();
  };
  const renderMHubProductNavigations = () => (
    <ul className={classes.ul}>
      <li>
        <a href="/" className={classes.logo}>
          <img alt="MHub Account Home" src={logo} />
        </a>
      </li>
      <li>
        <a href="/subscription">
          <Typography variant="body1" className={classes.navigationFont}> Account </Typography>
        </a>
      </li>
    </ul>
  );
  const renderNavigationButton = (title, icon, open) => (
    <IconButton onClick={open} className={classes.button}>
      <Tooltip title={title} placement="bottom">
        <Icon>
          <img alt="menu" src={icon} />
        </Icon>
      </Tooltip>
    </IconButton>
  );

  const renderDesktopView = () => (
    <>
      <Grid
        item
        xs={11}
      >
        {renderMHubProductNavigations()}
      </Grid>
      <Grid
        item
        xs={1}
        className={classes.navigationsContainer}
      >
        <DropdownMenu
          className={classes.menu}
          headerNode={renderMenuHeader(user.name)}
          data={[
            {
              children: renderMenuItem('Profile', ProfileIcon),
              onClick: () => handleOpenURL(`${profileUrl}/profile/`),
            },
            {
              children: renderMenuItem('MHub account', HomeIcon),
              onClick: () => handleOpenURL('/'),
            },
            {
              children: renderMenuItem('Sign out', SignoutIcon),
              onClick: handleOpenState('signOut'),
            },
          ]}
        >
          {({
            open,
          }) => renderNavigationButton('Profile', ProfileIcon, open)}
        </DropdownMenu>
      </Grid>
    </>
  );

  const renderMobileView = () => (
    <>
      <Grid item>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleMenu}
        >
          <Icon>
            <img alt="menu" src={BurgerMenuIcon} />
          </Icon>
        </IconButton>
      </Grid>
      <Grid
        item
        style={{
          margin: 'auto',
        }}
      >
        {renderHeading()}
      </Grid>
    </>
  );

  const renderAppBar = () => (
    isDesktop ? renderDesktopView() : renderMobileView()
  );

  return (
    <MuiAppBar className={classes.root} color="inherit" position="static">
      <Grid container alignItems="center" className={classes.headerContainer} justifyContent="space-between">
        {renderAppBar()}
      </Grid>
      <Dialog open={activeStates.signOut} onClose={handleCloseState('signOut')}>
        <DialogTitle>Confirm to sign out?</DialogTitle>
        <Grid container justifyContent="center" className={classes.controls}>
          <Button variant="outlined" onClick={handleCloseState('signOut')}>
            No
          </Button>
          <Button variant="contained" onClick={handleSignOut}>
            Yes
          </Button>
        </Grid>
      </Dialog>
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  toggleMenu: PropTypes.func,
};

AppBar.defaultProps = {
  toggleMenu: () => {},
};

export default AppBar;
