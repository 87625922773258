import React, {
  lazy, Suspense,
} from 'react';
import {
  Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  RouteAuthenticated, LoadingPage,
} from '../../components';

const Account = lazy(() => import('./screens/Account'));

const Subscription = ({
  match,
}) => (
  <Suspense fallback={<LoadingPage isLoading />}>
    <Switch>
      <RouteAuthenticated path={match.path} exact component={Account} />
    </Switch>
  </Suspense>
);

Subscription.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default Subscription;
